import React from "react";
import { Dispatch, SetStateAction } from "react";
import { BASE_TWITTER_RE_TWEET_URL } from "./constants";

export const hasTextContent = (node: React.ReactNode) => {
  const childArray = React.Children.toArray(node);

  return childArray.some((child) => {
    return typeof child === "string" || typeof child === "number";
  });
};

export const handleCopyText = async (
  text: string,
  setDisabled: Dispatch<SetStateAction<boolean>>,
) => {
  await navigator.clipboard.writeText(text);

  setDisabled(true);
  setTimeout(() => {
    setDisabled(false);
  }, 2500);
};

export const hexToDecimalInRange = (hex: string, range = 100_000) => {
  const decimalNumber = parseInt(hex, 16);

  return (decimalNumber % range) + 1;
};

export const removePadding = (paddedAddress: string | undefined) => {
  if (!paddedAddress) return;

  const address = paddedAddress.slice(26);

  return `0x${address}`;
};

export const decToHex = (decimal: number) => {
  return `0x${decimal.toString(16)}`;
};

export const removeScientificNotation = (value: string) => {
  return Number(value).toLocaleString("fullwide", { useGrouping: false });
};

export const formatNumber = (num: number): string => {
  return num.toLocaleString().replace(/,/g, ".");
};

export const buildNftID = (nftContract: string, tokenId: number) => {
  return `${nftContract}-${tokenId}`;
};

export const buildMapEntityByID = (entities: any[]) => {
  return new Map<string, any>(
    entities.map((entity: any) => [entity.id, entity]),
  );
};

export const withHOF = (fn: any) => {
  return () =>
    (...arg: any) =>
      fn(...arg);
};

export const buildRetweetUrl = (text: string, url?: string): string =>
  `${BASE_TWITTER_RE_TWEET_URL}?text=${text}&url=${url}`;

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const getPagePaginationList = (
  itemsCount: number,
  itemsPerPage: number,
  currentPage = 0,
  separator = "...",
): (string | number)[] => {
  const totalPages = Math.ceil(itemsCount / itemsPerPage);
  let startPages = 3;
  let endPages = 3;
  let showMidPage = false;

  if (currentPage <= 3) {
    startPages = 3;
  } else if (currentPage <= totalPages - endPages) {
    // if current is next to start pages end => don't separate it with ... enlarge start by 1
    if (currentPage === 4) {
      startPages = currentPage;
      // if current is next to end pages start => don't separate it with ... enlarge end by 1
    } else if (currentPage === totalPages - endPages) {
      endPages = totalPages - currentPage + 1;
    } else {
      showMidPage = true;
    }
  }

  startPages = Math.min(startPages!, totalPages);
  endPages = Math.min(endPages!, totalPages);

  const paginationList: (string | number)[] = [];

  // add starting pages
  for (let i = 1; i <= startPages; i++) {
    paginationList.push(i);
  }

  // add middle page and/or separator
  if (startPages < totalPages - endPages) {
    paginationList.push(separator);
    if (showMidPage) {
      paginationList.push(currentPage);
      paginationList.push(separator);
    }
  }

  // add end pages
  for (
    let i = Math.max(totalPages - endPages + 1, startPages + 1);
    i <= totalPages;
    i++
  ) {
    paginationList.push(i);
  }

  return paginationList;
};

export function tailwindColorToHexColor(color: string) {
  switch (color) {
    case "green":
      return "#00A09A";
    case "red":
      return "#D60A51";
    case "yellow":
      return "#F9B233";
  }
}
